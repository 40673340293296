export const useWorkspacesStore = defineStore('workspaces', {
  state: () => {
    return {
      total: 0,
      currentPage: 1,
      perPage: 100,
      searchQuery: '',
      workspaces: [],
    };
  },

  actions: {
    async fetchWorkspaces() {
      const response = await useApi.get('/workspaces').catch(() => {
        useToast.error(useT('bcd101'));
      });

      if (response) {
        this.total = response.total;
        this.workspaces = response.results;
      }
    },

    async create(body) {
      try {
        const response = await useApi.post('/workspaces', {
          body,
        });

        return response;
      } catch (error) {
        if (error.response?.status === 400 || error.response?.status === 409) {
          useToast.error(useT('bcd238'));
          return;
        }

        console.error(error);
        useToast(useT('bcd100'));
      }
    },

    async access(domain) {
      const response = await useApi.post(`/workspaces/${domain}/access`).catch(() => {
        useToast.error(useT('bcd99'));
      });

      if (response) {
        useToast(useT('bcd98'));
        const storage = useSessionStorage(`access-${domain}-validUntil`, '');
        storage.value = response.validUntil;
      }

      return response;
    },

    async getUsersLogs(domain) {
      const response = await useApi.get(`/workspaces/${domain}/users/logs`).catch(() => {
        useToast.error(useT('bcd97'));
      });

      return response;
    },
  },

  getters: {
    getWorkspaces(state) {
      return _orderBy(filterSearchInFields({
        items: state.workspaces,
        fields: ['name', 'domain'],
        query: state.searchQuery,
      }) || [], 'name', 'asc').slice(state.perPage * (state.currentPage - 1), state.perPage * state.currentPage);
    },
  },
});
